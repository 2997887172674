import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader, ThemeProvider } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../material-snackbars';

import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import './styles.css'
import { Button, CircularProgress, Grid, Paper, Typography } from '@mui/material';
import { cancelFaceLivenessSession, createFaceLivenessSession, getFaceLivenessSessionResult, restartFaceLivenessSession } from '../../services/mexx-2-face/faceliveness.service';
import { env } from '../../env';
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole';
import ApiMexx2Face from '../../services/mexx-2-face/apiMexx2Face';
import CustomFaceLivenessDetector from '../custom-face-liveness-detector';
import FaceRecognitionNear from '../images/face_recognition_near';
import FaceRecognitionFar from '../images/face_recognition_far';
import { createLivenessHistory } from '../../services/livenesshistory.service';
 
I18n.putVocabularies(translations);
I18n.setLanguage('pt');

//import { getRekognitionClient } from './rekognition'

//let  urlapi= 'https://dev.api.2do.mexx.ai:5000/api/users';
//urlapi='https://192.168.0.103:5000/api/users';

const dictionary = {
	// use default strings for english
	en: null,
	ptbr: {
	  instructionsHeaderHeadingText: 'Verificação de vida',
	  instructionsHeaderBodyText:
		'Você passará por um processo de verificação facial para provar que é uma pessoa real.',
	  instructionListStepOneText:
		'Quando uma moldura oval aparecer, preencha ela com seu rosto durante 7 segundos.',
	  instructionListStepTwoText: 'Maximize o brilho da tela.',
	  instructionListStepThreeText:
		'Certifique-se de que seu rosto não esteja coberto por óculos de sol ou máscara.',
	  instructionListStepFourText:
		'Vá para uma área bem iluminada que não esteja exposta à luz solar direta.',
	  photosensitivyWarningHeadingText: 'Aviso de fotossensibilidade',
	  photosensitivyWarningBodyText:
		'Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.',
	  instructionListHeadingText:
		'Siga as instruções para concluir a verificação:',
	  goodFitCaptionText: 'Bom encaixe',
	  tooFarCaptionText: 'Longe demais',

	  instructionsBeginCheckText: 'Iniciar leitura do rosto',
	  photosensitivyWarningInfoText:
		'Uma pequena porcentagem de indivíduos pode sofrer ataques epiléticos quando expostos a luzes coloridas. Tenha cuidado se você ou alguém da sua família tiver uma condição epiléptica.',
	  goodFitAltText:
		"Ilustração do rosto de uma pessoa, encaixando-se perfeitamente dentro de um oval.",
	  tooFarAltText:
		"Ilustração do rosto de uma pessoa dentro de um oval; há uma lacuna entre o perímetro da face e os limites do oval.",
	  cameraMinSpecificationsHeadingText:
		'A câmera não atende às especificações mínimas',
	  cameraMinSpecificationsMessageText:
		'A câmera deve suportar pelo menos 320*240 de resolução e 15 quadros por segundo.',
	  cameraNotFoundHeadingText: 'Câmera não acessível.',
	  cameraNotFoundMessageText:
		'Verifique se a câmera está conectada e se as permissões da câmera estão ativadas nas configurações antes de tentar novamente.',
	  retryCameraPermissionsText: 'Tentar novamente',
	  cancelLivenessCheckText: 'Cancelar verificação de vivacidade',
	  recordingIndicatorText: 'gravando...',
	  hintMoveFaceFrontOfCameraText: 'Mova o rosto na frente da câmera',
	  hintTooManyFacesText: 'Certifique-se de que apenas 1(um) rosto esteja na frente da câmera',
	  hintFaceDetectedText: 'Rosto detectado',
	  hintCanNotIdentifyText: 'Mova o rosto na frente da câmera',
	  hintTooCloseText: 'Volte um pouco',
	  hintTooFarText: 'Aproxime-se',
	  hintHoldFacePositionCountdownText: 'Mantenha a posição do rosto durante a contagem regressiva',
	  hintConnectingText: 'Conectando...',
	  hintVerifyingText: 'Verificando...',
	  hintIlluminationTooBrightText: 'Mover para a área mais escura',
	  hintIlluminationTooDarkText: 'Move to brighter area',
	  hintIlluminationNormalText: 'Mover para uma área mais clara',
	  hintHoldFaceForFreshnessText: 'Segure e aguarde firme',

	  
	},
  };


class LivenessFaceClass extends Component {

	constructor(props) {
		super(props);

		//alert('constructor');
		
		this.state = {
			createLivenessApiData : "",
			loading: false,
			openNotification: false,
			notificationVariant: 'error',
			notificationMessage: '',
			resultList: [],
			error: false
		};


		this.closeNotification = this.closeNotification.bind(this);
		this.fetchCreateLiveness = this.fetchCreateLiveness.bind(this);
		this.handleAnalysisComplete = this.handleAnalysisComplete.bind(this);
		this.validateFaceLivenessSessionResultData = this.validateFaceLivenessSessionResultData.bind(this)
		this.handleUserCancel = this.handleUserCancel.bind(this);
		this.handleError = this.handleError.bind(this);
		this.handleRestartSession = this.handleRestartSession.bind(this)
		this.restartSession = this.restartSession.bind(this)
	}

	componentWillReceiveProps(nextProps){
	}    

	componentDidMount() {
		//alert('didmount');
		if (ApiMexx2Face == null) {
			this.props.handleErrorFaceLivenessSession()
		}

		this.fetchCreateLiveness();
	}

	fetchCreateLiveness = async () => {
		this.setState({ loading:true });
		try {
			let response = await createFaceLivenessSession()
			if (response && response.success != null && response.success && response.data != null)
				await this.setState({ createLivenessApiData: response.data })
			
			let username = env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_NAME;
			let password=  env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_PASSWORD; 
			let email = env.REACT_APP_MEXX_2FACE_AWS_REKOGNITION_CREDENTIAL_USER_EMAIL;

			Mexx2DOConsole.log("=== fetchCreateLiveness ===")
			Mexx2DOConsole.log(env)
			Mexx2DOConsole.log(username);
			Mexx2DOConsole.log(email);

				try {
					//await Auth.signOut({ global: true }).then(async (p) => {
						await Auth.signIn({
						username,
						password,
						attributes: {
							email,          // optional
						},			  
						autoSignIn: { // optional - enables auto sign in after user is confirmed
							enabled: true,
						}
						}).then((user) => {
							if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
							const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
							Auth.completeNewPassword(
								user, // the Cognito User Object
								password,
							)
								.then((user) => {
								// at this time the user is logged in if no MFA required
								Mexx2DOConsole.log('PASSWORD ALTERADO SUECESSO!');
								//Mexx2DOConsole.log(user);
								})
								.catch((e) => {
								Mexx2DOConsole.log('error complete New Password:', e);
								});
							} else {
							// other situations
							Mexx2DOConsole.log('other situations');
							}
							Mexx2DOConsole.log('SUCESSO!');
							//Mexx2DOConsole.log(user);
							//this.setState({loading: false, openNotification: true, notificationVariant: 'success', notificationMessage: 'Autenticação realizada com sucesso.'});
							this.props.changeButtonLabel();
						})
						.catch((e) => {
							Mexx2DOConsole.log('error signing up:', e);
							this.setState({
								loading: false,
								openNotification: true,
								notificationVariant: 'error',
								notificationMessage: "Não foi possível estabelecer conexão com o serviço de verificação de vida. Aguarde um instante e tente novamente."
							});
							setTimeout(function() {
								window.location.reload();
							}, 5000);
						});

                   /*
              

					/*	
					}).catch((e) => {
						Mexx2DOConsole.log('error sign Out:', e);
						let message = JSON.stringify(e)
						if (e != null) {
							if (e.message != null) {
								if (e.message === 'Access Token has been revoked' || e.message === 'Refresh Token has been revoked') {
									this.setState({
										loading: false,
										openNotification: true,
										notificationVariant: 'error',
										notificationMessage: "Não foi possível estabelecer conexão com o serviço de verificação de vida. Aguarde um instante e tente novamente."
									});
									setTimeout(function() {
										window.location.reload();
									}, 5000);
								}
							}
						}
						this.restartSession()
					});

					*/
				} catch (error) {
					Mexx2DOConsole.log('error login up:', error);
					//this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
				}
				
	
				this.setState({ loading:false });
				this.props.changeButtonLabel();
	
			} catch (error) {
				//Mexx2DOConsole.log('error get session:', error);
				this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
			}
  }

	validateFaceLivenessSessionResultData = (data) => {
		//alert(0);
		if (data != null && data.confidence != null && data.isalive != null && data.base64image != null && data.imagetempurl != null){
			//alert(1);
			if(data.status == "SUCCEEDED"){
				//alert(2);
				if(data.confidence+0.0 >= 70.0){
					//alert(3);
					return true
				}
			}
		}
		return false
	}

	handleAnalysisComplete = async () => {
		this.setState({ loading:true });
		try {
			let response = await getFaceLivenessSessionResult(this.state.createLivenessApiData)
			if (response && response.success != null && response.success && this.validateFaceLivenessSessionResultData(response.data)) {
				this.setState({ loading: false })
				this.props.getFaceLivenessSessionResults(response.data)
			} else {
				this.setState({
					loading: false,
					error: true,
					openNotification: true,
					notificationVariant: 'error',
					notificationMessage: "Não identificamos uma pessoa viva na verificação. Favor atentar para as observações e tentar novamente."
				})
			}

			if(response && response.success != null && response.success && response.data) {
			
			
				this.handleCreateLivenessHistory(response.data.sessionid, response.data.confidence, response.data.status );

			}
		} catch (error) {
			this.setState({
				loading: false, openNotification: true, notificationVariant: 'error',notificationMessage: JSON.stringify(error)});
		}
  }

  handleCreateLivenessHistory = async (sessionid, confidence, status) => {
		
	try {
		let data = new FormData()
		if(this.props.document){
			data.append('RequestingUserDocument', this.props.document)
		}
		if(this.props.name){
			data.append('RequestingUserName', this.props.name)
		}
		if(this.props.email){
			data.append('RequestingUserEmail', this.props.email)
		}
		data.append('SessionId', sessionid)
		data.append('Confidence',confidence)
		data.append('Status', status)
		if(window && window.location &&  window.location.href){
			const currentURL = window.location.href;
			data.append('absoluteOrigin', currentURL)
		}
		if(this.props.hirerId){
			data.append('hirerId', this.props.hirerId)
		}
		var response = await createLivenessHistory(data)
		if(response && response.success) {

			this.props.handleLivenessSession(sessionid);
		}
			
			
		
	} catch (error) {
		//Mexx2DOConsole.log('error cancel session:', error);
		// this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
	}

}

	handleUserCancel = async () => {
		
		try {
			await this.restartSession()
		} catch (error) {
			//Mexx2DOConsole.log('error cancel session:', error);
			this.setState({loading: false, openNotification: true, notificationVariant: 'error', notificationMessage: 'error cancel session:'+JSON.stringify(error)});
		}
	
	}

	handleRestartSession = async (e) => {
		e.preventDefault()
		await this.restartSession()
	}

	restartSession = async () => {
		try {
			this.setState({ loading:true })

			let response = await restartFaceLivenessSession(this.state.createLivenessApiData)
			if (response && response.success != null && response.success && response.data != null && response.data.sessionid != null && response.data.sessionid.length > 0) {
				this.setState({
					loading: false,
					error: false,
					createLivenessApiData: response.data.sessionid,
				})
			} else {
				this.setState({
					loading: false,
					/*openNotification: true,
					notificationVariant: 'error',
					notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"*/
				})
			}
		} catch (error) {
			this.setState({
				loading: false,
				openNotification: true,
				notificationVariant: 'error',
				notificationMessage: JSON.stringify(error)
			});
		}
	}

	handleError = async (error) => {
			Mexx2DOConsole.log(error);
			try {

				//this.setState({ loading:true });

				//let response = await cancelFaceLivenessSession(this.state.createLivenessApiData)
				//if (response && response.success != null && response.success && response.data != null) {
					this.setState({
						loading: false,
						openNotification: true,
						notificationVariant: 'error',
						notificationMessage: "Ocorreu um erro no processo. Leia as orientações abaixo!"
					});
		//		} else {
		//			this.setState({
        //    loading: false,
            /*openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"*/
         // })
		//		}
				this.setState({ error: true })
			} catch (error) {
				this.setState({
					loading: false,
					openNotification: true,
					notificationVariant: 'error',
					notificationMessage: JSON.stringify(error)
				});
			}
	
	};  
	closeNotification() {
		this.setState({ openNotification: false });
	}
	render() {
		return (
			<div style={{paddingLeft:0, marginLeft:0}}>		
				
				{this.state.loading ? (
					<Overlay>
						<CircularProgress color="mainsecondary" />
					</Overlay>				
				) : (
					<>
					{!this.state.error ?
					(<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography variant="h2" className={this.props.class} style={{ color: '#1351b4', margin: 0 }}>
                Para prosseguir, precisamos confirmar sua identidade
              </Typography>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<ThemeProvider>
								<CustomFaceLivenessDetector
									sessionId={this.state.createLivenessApiData}
									handleAnalysisComplete={this.handleAnalysisComplete}
									handleUserCancel={this.handleUserCancel}
									handleError={this.handleError} />
							</ThemeProvider>
						</Grid>
					</Grid>)
					:
					(
									<Grid container spacing={2}>
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold', paddingTop: 30 }}>
												Não foi possível realizar a leitura do seu rosto. Para prosseguir, é necessário seguir as orientações abaixo: 
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
											<Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;Esteja em uma área <strong>bem iluminada</strong> que não esteja exposta à luz solar direta;
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
											<Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;<strong>Não cubra o rosto</strong> com óculos de sol, máscara ou demais adereços;
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
											<Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;<strong>Maximize o brilho da tela</strong> de seu dispositivo para facilitar a leitura do rosto;
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
											<Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;Durante o processo, <strong>posicione o rosto na moldura oval</strong> até o final da contagem.
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 15 }}>
											<Typography variant="body1" className={this.props.class}>
                        Siga o exemplo abaixo:
                      </Typography>
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
											<FaceRecognitionNear
												style={{ height: 150 }}
                				divStyle={{ marginRight: 5 }} />
											<FaceRecognitionFar
												style={{ height: 150 }}
                				divStyle={{ marginLeft: 5 }} />
										</Grid>
										<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 10, display: 'flex', justifyContent: 'center' }}>
                      <Button
                        onClick={this.handleRestartSession}
                        variant="contained"
                        color="mainprimary"
                        style={{ padding: '8px 16px', textTransform: 'captalize!important' }} >
                        Tente novamente
                      </Button>
                  	</Grid>
									</Grid>)
					}
					</>
				)}
				
				<CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />					
			</div>
		);
	}
}
LivenessFaceClass.propTypes = {
	handleErrorFaceLivenessSession: PropTypes.func.isRequired,
	getFaceLivenessSessionResults: PropTypes.func.isRequired
};
const mapStateToProps = state => ({
	
});
export default LivenessFaceClass;

