import Api from './api';

export const createLivenessHistory = async (formData) => { 
  let data = []

  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }

  await Api.post(`/livenesshistory/create`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

