import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CssBaseline, Container, CircularProgress, Grid, Typography, TextField, Button, Paper, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { withStyles } from '@mui/styles'
import { Styles } from '../../styles/material-styles'
import { Overlay } from '../../styles/global'
import Api from '../../services/api'
import CustomizedSnackbars from '../../components/material-snackbars'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { injectIntl } from 'react-intl'
import { isEmail } from '../../utils/mask/emailTestMask'
import Logo from '../../components/logo/logo'
import moment from 'moment'
import Dropzone from 'react-dropzone'
import { DeleteForever } from '@mui/icons-material'
import BasicSelect from '../../components/select/BasicSelect'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'
import { getConfigurationsByRoute } from '../../services/organization.service'
import { getAllByParentOrganizationCategoryId } from '../../services/organizationcategor.service'
import { createClientExternalCalled, validateExternalCalled } from '../../services/called.service'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { cpfMask, validateCpf } from '../../utils/cpfUtils'
import { randomIntFromInterval } from '../../utils/numberUtils'
import CustomOrganizationField from '../../components/custom-organization-field'
import CPFImage from '../../components/images/cpf'
import IdentidadeCPFImage from '../../components/images/identidade-cpf'
import { isInvalidFileType } from '../../utils/fileUtils'
import LogoGovBr from '../../components/logo/logo-gov-br'
import LogoSelfie from '../../components/logo/logo-selfie'
import { ThemeProvider } from '@aws-amplify/ui-react';
import LivenessFaceClass from '../../components/liveness-face-class';
import './styles.css'


let currentTab = 0
let canvas = null;
let globalfiles = null;

class Home extends Component {
  constructor(props) {
    super(props)

    this.itemInitialState = {
      name: '',
      document: '',
      email: '',
      confirmEmail: '',
      hirerId: 0,
      organizationId: 0,
      rootOrganizationCategoryId: 0,
      organizationCategoryId: 0,
      typeId: 0,
      subjectId: 0,
      subjectValue: 0,
      reasonId: 0,
      reasonValue: 0,
      serviceId: 0,
      serviceValue: 0
    }

    this.state = {
      apiError: false,
      sessionId : null,
      rekognitionErrorMessage: '',
      rekognitionError: false,
      loading: false,
      item: this.itemInitialState,
      itemError: {
        name: '',
        document: '',
        email: '',
        confirmEmail: '',
        rootOrganizationCategoryId: false,
        organizationCategoryId: false,
        typeId: false,
        file: false,
        selfieFiles: false,
        selfieFilesText: "O campo 'Anexos' é obrigatório",
        documentFiles: false
      },
      validationofexistingcalls: false,
      backbuttonurl: '',
      filesUpload: {},
      CalledServiceSettingsId: 0,
      CalledServiceSettingsOrganizationFields: [],
      CalledServiceSettingsFileUploadsNotRecognition: [],
      CalledServiceSettingsFileUploads: [],
      subject: { organizationfieldtype: 5 },
      reason: { organizationfieldtype: 5 },
      service: { organizationfieldtype: 5 },
      selfieFiles: [],
      documentFiles: [],
      files: [],
      rootOrganizationCategoryList: [],
      subOrganizationCategoryList: [],
      typeList: [],
      notificationVariant: 'success',
      notificationMessage: '',
      openNotification: false,

      createLivenessApiData : "",
			showliveness: false,
      showform: true,
      turnonfacelivenessvalidation: false,
      step: 1,
			referenceimage: "",
			urlreferenceimage: "",
      footerMessage : "",
      renderFooterMessage : false

    }

    this.getFirstOrganization = this.getFirstOrganization.bind(this)
    this.getAllByParentOrganizationCategoryId = this.getAllByParentOrganizationCategoryId.bind(this)
    this.closeNotification = this.closeNotification.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.validateStepOneForm = this.validateStepOneForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSUbmitForm = this.handleSUbmitForm.bind(this)
    this.compareEmails = this.compareEmails.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.removeItems = this.removeItems.bind(this)
    this.removeSelfFilesItems = this.removeSelfFilesItems.bind(this)
    this.removeDocumentFilesItems = this.removeDocumentFilesItems.bind(this)
    this.removeFilesUploadItems = this.removeFilesUploadItems.bind(this)
    this.notEventChange = this.notEventChange.bind(this)
    this.formatOptions = this.formatOptions.bind(this)
    this.validateNotificationMessageType = this.validateNotificationMessageType.bind(this)
    this.validateFiles = this.validateFiles.bind(this)
    this.getFaceLivenessSessionResults = this.getFaceLivenessSessionResults.bind(this)
    this.handleErrorFaceLivenessSession = this.handleErrorFaceLivenessSession.bind(this)
    this.showSnackbar = this.showSnackbar.bind(this)
    this.changeButtonLabel = this.changeButtonLabel.bind(this)
    this.minorGuide = this.minorGuide.bind(this)
  }

  closeNotification = _ => this.setState({ openNotification: false })

  async componentDidMount() {
    if (Api === null || typeof Api === 'undefined') {
      this.setState({ apiError: true })
    } else {
      await this.getFirstOrganization()
    }
  }

  getFirstOrganization = async () => {
    this.setState({ loading: true })

    let response = await getConfigurationsByRoute("alterar-email-na-conta")
    if (response && response.success) {
      if (response.data != null) {
        let orgData = {
          id: 0,
          hirerId: 0,
          typeList: [],
          rootOrganizationCategoryList: [],
          subjectId: 0,
          subject: { organizationfieldtype: 5 },
          reasonId: 0,
          reason: { organizationfieldtype: 5 },
          serviceId: 0,
          service: { organizationfieldtype: 5 },
        }

        if (response.data.id != null)
          orgData.id = response.data.id
        if (response.data.hirerid != null)
          orgData.hirerId = response.data.hirerid
        if (response.data.subject && response.data.subject.id > 0) {
          orgData.subjectId = response.data.subject.id
          orgData.subject = response.data.subject
        }
        if (response.data.reason && response.data.reason.id > 0) {
          orgData.reasonId = response.data.reason.id
          orgData.reason = response.data.reason
        }
        if (response.data.service && response.data.service.id > 0) {
          orgData.serviceId = response.data.service.id
          orgData.service = response.data.service
        }

        if (response.data.calledservicesettingsorganizationfields && response.data.calledservicesettingsorganizationfields.length > 0) {
          Mexx2DOConsole.log("=== response.data.calledservicesettingsorganizationfields ===")
          Mexx2DOConsole.log(response.data.calledservicesettingsorganizationfields)
          Mexx2DOConsole.log(typeof response.data.calledservicesettingsorganizationfields)
          response.data.calledservicesettingsorganizationfields.forEach(item => {
            let initValue = (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype !== 1) ? (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype === 3) ? false : null : ""

            this.setState(prevState => ({
              item: {
                ...prevState.item,
                [`ofield-${item.organizationfield.id}`]: initValue
              },
              itemError: {
                ...prevState.itemError,
                [`ofield-${item.organizationfield.id}`]: false,
                [`ofield-${item.organizationfield.id}-text`]: ''
              }}))
          })
        }
        if (response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0) {
          response.data.calledservicesettingsfileuploads.forEach(item => {
            this.setState(prevState => ({
              filesUpload: {
                ...prevState.filesUpload,
                [`files-${item.id}`]: []
              }}))
          })
        }

        /*let calledServiceSettingsFileUploadsNotRecognition = response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0 ? response.data.calledservicesettingsfileuploads.filter(element => (element.calledservicesettingsfileuploadvalidations == null || (element.calledservicesettingsfileuploadvalidations != null && element.calledservicesettingsfileuploadvalidations.length == 0))) : []*/
        let calledServiceSettingsFileUploadsNotRecognition = []

        let calledServiceSettingsFileUploadsRecognition = response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0 ? response.data.calledservicesettingsfileuploads : []
        /*if (response.data.turnonfacelivenessvalidation != null && response.data.turnonfacelivenessvalidation) {
          calledServiceSettingsFileUploadsRecognition = response.data.calledservicesettingsfileuploads && response.data.calledservicesettingsfileuploads.length > 0 ? response.data.calledservicesettingsfileuploads.filter(element => (element.calledservicesettingsfileuploadvalidations != null && element.calledservicesettingsfileuploadvalidations.length > 0)) : []
        }*/

        this.setState(prevState => ({
          item: {
            ...prevState.item,
            organizationId: orgData.id,
            hirerId: orgData.hirerId,
            subjectId: orgData.subjectId,
            reasonId: orgData.reasonId,
            serviceId: orgData.serviceId,
          },
          showliveness: response.data.turnonfacelivenessvalidation != null ? response.data.turnonfacelivenessvalidation : false,
          turnonfacelivenessvalidation: response.data.turnonfacelivenessvalidation != null ? response.data.turnonfacelivenessvalidation : false,
          CalledServiceSettingsId: response.data.calledservicesettingsid,
          CalledServiceSettingsOrganizationFields: response.data.calledservicesettingsorganizationfields && response.data.calledservicesettingsorganizationfields.length > 0 ? response.data.calledservicesettingsorganizationfields.sort((a, b) => a.order - b.order) : [],
          CalledServiceSettingsFileUploadsNotRecognition: calledServiceSettingsFileUploadsNotRecognition.length > 0 ? calledServiceSettingsFileUploadsNotRecognition.sort((a, b) => a.order - b.order) : [],
          CalledServiceSettingsFileUploads: calledServiceSettingsFileUploadsRecognition.length > 0 ? calledServiceSettingsFileUploadsRecognition.sort((a, b) => a.order - b.order) : [],
          subject: orgData.subject && orgData.subject.organizationfieldoptionsvalues && orgData.subject.organizationfieldoptionsvalues.length > 0 && orgData.subject.organizationfieldtype && orgData.subject.organizationfieldtype === 4 ? this.formatOptions(orgData.subject) : orgData.subject,
          reason: orgData.reason && orgData.reason.organizationfieldoptionsvalues && orgData.reason.organizationfieldoptionsvalues.length > 0 && orgData.reason.organizationfieldtype && orgData.reason.organizationfieldtype === 4 ? this.formatOptions(orgData.reason) : orgData.reason,
          service: orgData.service && orgData.service.organizationfieldoptionsvalues && orgData.service.organizationfieldoptionsvalues.length > 0 && orgData.service.organizationfieldtype && orgData.service.organizationfieldtype === 4 ? this.formatOptions(orgData.service) : orgData.service,
          validationofexistingcalls: response.data.validationofexistingcalls != null ? response.data.validationofexistingcalls : false,
          footerMessage: response.data.footermessage != null ? response.data.footermessage : null,
          renderFooterMessage : true
          
        }))
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        loading: false,
        apiError: true
      })
    }
  }

  convertHtmlOption = value => {
    
    let body = value
    let temp = document.createElement("div")
    temp.innerHTML = body
    let found = null
    for (let i in temp.children) {
        if (temp.children[i].innerHTML && temp.children[i].innerHTML.toString().trim() != '<br>' && temp.children[i].innerHTML.toString().trim().length > 0) {
            found += temp.children[i].innerHTML.toString()
        }
    }
    let sanitized = temp.textContent.trim() || temp.innerText.trim() || found

   
    return sanitized
}

  formatOptions = (organizationField) => {
    if (organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.length > 0) {
      organizationField.organizationfieldoptionsvalues = organizationField.organizationfieldoptionsvalues.sort((a, b) => a.id - b.id)
    }
    return organizationField
  }

  getAllByParentOrganizationCategoryId = async (id) => {
    this.setState({ loading: true })
    let response = await getAllByParentOrganizationCategoryId(id)
    if (response && response.success) {
      if (response.data != null) {
        if (response.data && response.data.length > 0)
          this.setState({ subOrganizationCategoryList: response.data })
      }
      this.setState({ loading: false })
    } else {
      this.setState({
        loading: false,
        openNotification: true,
        notificationVariant: 'error',
        notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"
      })
    }
  }

  removeItems = () => {
    this.setState({ files: [] })
  }

  removeSelfFilesItems = () => {
    this.setState({ selfieFiles: [] })
  }

  removeFilesUploadItems = (stateName) => {
    this.setState(prevState => ({ filesUpload: { ...prevState.filesUpload, [stateName]: [] } }))
  }

  removeDocumentFilesItems = () => {
    this.setState({ documentFiles: [] })
  }

  removeItem = (file) => {
    let array = [...this.state.files];
    let index = array.indexOf(file)
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ files: array });
    }
  }

  compareEmails = value => {
    value !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
  }

  handleChange = (event) => {
    let { name, value } = event.target

    const plainTextRegex = /^[\x20-\x7EÀ-ÿ]*$/;

    if (plainTextRegex.test(value)) {
    } else {
      // Remove caracteres fora do ASCII
      const sanitizedValue = value.replace(/[^\x20-\x7EÀ-ÿ]/g, "");
      value = sanitizedValue;
    }

    if (name === 'name') {
      value.trim().length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    } else if (name === 'document') {
      value = cpfMask(value)
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } })) : (value.length !== 14 || !validateCpf(value)) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    } else if (name === 'email') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } })) : !isEmail(value.toLowerCase()) ? this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
      this.compareEmails(value)
    } else if (name === 'confirmEmail') {
      value.length === 0 ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } })) : value.toLowerCase() !== this.state.item.email ? this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } })) : this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    } else if (name === 'rootOrganizationCategoryId') {
      if (value > 0) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, rootOrganizationCategoryId: false } }))
        this.getAllByParentOrganizationCategoryId(value);
      }
    } else if (name === 'organizationCategoryId') {
      if (value > 0)
        this.setState(prevState => ({ itemError: { ...prevState.itemError, organizationCategoryId: false } }))
    }

    if (name === 'email') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value.toLowerCase()
        }
      }));
    }
    else if (name === 'confirmEmail') {
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value.toLowerCase()
        }
      }));
    }

    else if (name !== 'name' || (name === 'name' && value.length <= 255))
      this.setState(prevState => ({
        item: {
          ...prevState.item,
          [name]: value
        }
      }));
  }

  handleChangeSelect = (value) => {
    if (value > 0)
      this.setState(prevState => ({ itemError: { ...prevState.itemError, typeId: false } }))
  
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        typeId: value
      }
    }))
  }

  onChangeCustomField = (fieldType, id, value, stateName) => {
    this.setState(prevState => ({
      item: {
        ...prevState.item,
        [stateName]: (fieldType != 1 && fieldType != 3) ? parseInt(value) : value
      }
    }))

    let itemData = this.state.CalledServiceSettingsOrganizationFields.find(item => item.organizationfieldid == id)

    if (fieldType != 1 && fieldType != 3) {
      let parseValue = parseInt(value)
      if (itemData != null) {
        if (itemData.isrequired) {
          if (parseValue > 0) {
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${id}`]: false } }))
          }
        }

        if (itemData.calledservicesettingsorganizationfieldvalidations && itemData.calledservicesettingsorganizationfieldvalidations.length > 0) {
          if (parseValue == itemData.calledservicesettingsorganizationfieldvalidations[0].organizationfieldoptionsvaluesid) {
            this.setState({
              notificationVariant: this.validateNotificationMessageType(itemData.calledservicesettingsorganizationfieldvalidations[0].messagetype),
              notificationMessage: itemData.calledservicesettingsorganizationfieldvalidations[0].message,
              openNotification: true
            })
          }
        }
      }
    } else {
      if (itemData && itemData.isrequired) {
        if (value && value.length > 0) {
          this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${id}`]: false } }))
        }
      }
    }
    Mexx2DOConsole.log("=== onChangeCustomField ===")
    Mexx2DOConsole.log(this.state.item)
  }

  validateNotificationMessageType = (messageType) => {
    switch(messageType) {
      case 2:
        return 'info'
      case 3:
        return 'warning'
      case 4:
        return 'error'
      case 1:
      default:
        return 'success'
    }
  }

  validateFiles = (itemId, acceptedFiles, fileLimit) => {
    Mexx2DOConsole.log("=== validateFiles ===")
    Mexx2DOConsole.log(acceptedFiles)
    Mexx2DOConsole.log(itemId)
    Mexx2DOConsole.log(fileLimit)
    if (acceptedFiles && acceptedFiles.length > fileLimit) {
      this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${itemId}`]: true, [`files-${itemId}-text`]: `Você precisa inserir no máximo ${fileLimit} anexos` } }), () => {
        Mexx2DOConsole.log("=== PrevState -> itemError ===")
        Mexx2DOConsole.log(this.state.itemError)
      })
    } else {
      this.setState(prevState => ({ filesUpload: { ...prevState.filesUpload, [`files-${itemId}`]: [...acceptedFiles] } }), () => {
        Mexx2DOConsole.log("=== PrevState -> filesUpload ===")
        Mexx2DOConsole.log(this.state.filesUpload)
      })
    }
  }

  validateForm = () => {
    let { item, itemError } = this.state
    let hasError = false

    let erros = {
      name: false,
      document: false,
      email: false,
      confirmEmail: false,
      rootOrganizationCategoryId: false,
      organizationCategoryId: false,
      typeId: false,
      file: false,
      selfieFiles: false,
      selfieFilesText: "O campo 'Anexos' é obrigatório",
      documentFiles: false
    }

    // valida nome
    if (item.name.trim().length === 0) {
      erros.name = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } }))
    } else {
      erros.name = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    }
    
    // valida CPF
    item.document = cpfMask(item.document)
    if (item.document.length === 0) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } }))
    } else if (item.document.length !== 14 || !validateCpf(item.document)) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } }))
    } else {
      erros.document = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    }
    
    // valida Email
    if (item.email.length === 0) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } }))
    } else if (!isEmail(item.email)) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } }))
    } else {
      erros.email = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
    }
    
    // valida Confirmação de Email
    if (item.confirmEmail.length === 0) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } }))
    } else if (item.confirmEmail !== this.state.item.email) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } }))
    } else {
      erros.confirmEmail = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    }

    this.state.CalledServiceSettingsOrganizationFields.forEach(item => {
      if (item.isrequired) {
        let itemData = this.state.item[`ofield-${item.organizationfield.id}`]
        let validation = `O campo '${item.label}' é obrigatório`
        if (item.requiredfieldmessage && item.requiredfieldmessage != null)
          validation = item.requiredfieldmessage
        
        if (item.organizationfield && item.organizationfield.organizationfieldtype && (item.organizationfield.organizationfieldtype !== 1 && item.organizationfield.organizationfieldtype !== 3)) {
          if (itemData && itemData > 0) {
            if (item.calledservicesettingsorganizationfieldvalidations && item.calledservicesettingsorganizationfieldvalidations.length > 0) {
              if (item.calledservicesettingsorganizationfieldvalidations[0] && item.calledservicesettingsorganizationfieldvalidations[0].messagetype && item.calledservicesettingsorganizationfieldvalidations[0].messagetype == 4 && parseInt(itemData) == item.calledservicesettingsorganizationfieldvalidations[0].organizationfieldoptionsvaluesid) {
                erros[`ofield-${item.organizationfield.id}`] = true
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: item.calledservicesettingsorganizationfieldvalidations[0].message } }))
              } else {
                erros[`ofield-${item.organizationfield.id}`] = false
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
              }
            } else {
              erros[`ofield-${item.organizationfield.id}`] = false
              this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
            }
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else if (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype === 3) {
          if (itemData && itemData.length > 0 && (itemData == true || itemData == false)) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else {
          if (itemData && itemData.length > 0) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        }
      }
    })

    this.state.CalledServiceSettingsFileUploads.forEach(item => {
      if(item.isrequired){
      let itemData = this.state.filesUpload[`files-${item.id}`]
      if (itemData == null || typeof itemData === 'undefined' || (itemData && itemData.length == 0)) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `O campo '${item.label}' é obrigatório` } }))
        erros[`files-${item.id}`] = true
      } else if (itemData.length > item.filelimit) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `Você precisa inserir no máximo ${item.filelimit} anexos` } }))
        erros[`files-${item.id}`] = true
      } else {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: false, [`files-${item.id}-text`]: `O campo '${item.label}' é obrigatório` } }))
        erros[`files-${item.id}`] = false
      }
      }
    })

    Object.values(erros).forEach(val => {
      if (val == true)
        hasError = true
    });

    return hasError
  }

  validateStepOneForm = () => {
    let { item, itemError } = this.state
    let hasError = false

    let erros = {
      name: false,
      document: false,
      email: false,
      confirmEmail: false,
      rootOrganizationCategoryId: false,
      organizationCategoryId: false,
      typeId: false,
      file: false,}

    // valida nome
    if (item.name.trim().length === 0) {
      erros.name = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: 'Campo obrigatório' } }))
    } else {
      erros.name = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, name: '' } }))
    }
    
    // valida CPF
    item.document = cpfMask(item.document)
    if (item.document.length === 0) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'Campo obrigatório' } }))
    } else if (item.document.length !== 14 || !validateCpf(item.document)) {
      erros.document = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: 'CPF inválido' } }))
    } else {
      erros.document = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, document: '' } }))
    }
    
    // valida Email
    if (item.email.length === 0) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Campo obrigatório' } }))
    } else if (!isEmail(item.email)) {
      erros.email = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: 'Email inválido' } }))
    } else {
      erros.email = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, email: '' } }))
    }
    
    // valida Confirmação de Email
    if (item.confirmEmail.length === 0) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'Campo obrigatório' } }))
    } else if (item.confirmEmail !== this.state.item.email) {
      erros.confirmEmail = true
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: 'E-mails não coincidem' } }))
    } else {
      erros.confirmEmail = false
      this.setState(prevState => ({ itemError: { ...prevState.itemError, confirmEmail: '' } }))
    }

    this.state.CalledServiceSettingsOrganizationFields.forEach(item => {
      if (item.isrequired) {
        let itemData = this.state.item[`ofield-${item.organizationfield.id}`]
        let validation = `O campo '${item.label}' é obrigatório`
        if (item.requiredfieldmessage && item.requiredfieldmessage != null)
          validation = item.requiredfieldmessage
        
        if (item.organizationfield && item.organizationfield.organizationfieldtype && (item.organizationfield.organizationfieldtype !== 1 && item.organizationfield.organizationfieldtype !== 3)) {
          if (itemData && itemData > 0) {
            if (item.calledservicesettingsorganizationfieldvalidations && item.calledservicesettingsorganizationfieldvalidations.length > 0) {
              if (item.calledservicesettingsorganizationfieldvalidations[0] && item.calledservicesettingsorganizationfieldvalidations[0].messagetype && item.calledservicesettingsorganizationfieldvalidations[0].messagetype == 4 && parseInt(itemData) == item.calledservicesettingsorganizationfieldvalidations[0].organizationfieldoptionsvaluesid) {
                erros[`ofield-${item.organizationfield.id}`] = true
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: item.calledservicesettingsorganizationfieldvalidations[0].message } }))
              } else {
                erros[`ofield-${item.organizationfield.id}`] = false
                this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
              }
            } else {
              erros[`ofield-${item.organizationfield.id}`] = false
              this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
            }
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else if (item.organizationfield && item.organizationfield.organizationfieldtype && item.organizationfield.organizationfieldtype === 3) {
          if (itemData && itemData.length > 0 && (itemData == true || itemData == false)) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        } else {
          if (itemData && itemData.length > 0) {
            erros[`ofield-${item.organizationfield.id}`] = false
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: false } }))
          } else {
            erros[`ofield-${item.organizationfield.id}`] = true
            this.setState(prevState => ({ itemError: { ...prevState.itemError, [`ofield-${item.organizationfield.id}`]: true, [`ofield-${item.organizationfield.id}-text`]: validation } }))
          }
        }
      }
    })

    this.state.CalledServiceSettingsFileUploadsNotRecognition.forEach(item => {
      let itemData = this.state.filesUpload[`files-${item.id}`]
      if (itemData == null || typeof itemData === 'undefined' || (itemData && itemData.length == 0)) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `O campo '${item.label}' é obrigatório` } }))
        erros[`files-${item.id}`] = true
      } else if (itemData.length > item.filelimit) {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: true, [`files-${item.id}-text`]: `Você precisa inserir no máximo ${item.filelimit} anexos` } }))
        erros[`files-${item.id}`] = true
      } else {
        this.setState(prevState => ({ itemError: { ...prevState.itemError, [`files-${item.id}`]: false, [`files-${item.id}-text`]: `O campo '${item.label}' é obrigatório` } }))
        erros[`files-${item.id}`] = false
      }
    })

    Object.values(erros).forEach(val => {
      if (val == true)
        hasError = true
    });

    return hasError
  }

  handleSubmitStep = async e => {
    e.preventDefault()

    this.setState({ loading: true })

    const { step } = this.state
    if (step === 1) {
      let validateForm = await this.validateStepOneForm()
      if (validateForm === false) {
        let { item } = this.state
      
        let { name, email, document } = this.state.item

        let data = new FormData()
        data.append('calledservicesettingsid', this.state.CalledServiceSettingsId)
        data.append('username', item.name)
        data.append('useremail', item.email)
        data.append('userdocument', item.document)
        data.append('hirerid', item.hirerId)

        let response = await validateExternalCalled(data)
        if (response && response.success) {
          let nextStep = 3
          if (this.state.turnonfacelivenessvalidation)
            nextStep = 2
          this.setState({ loading: false, step: nextStep })
        } else {
          if (response && response.errors && response.errors[0]) {
            let error = response.errors[0]
            if (error === 'Face_Comparisson_Error' || error === 'Document_Comparisson_Error' || error === 'Face_And_Document_Comparisson_Error') {
              this.setState({
                loading: false,
                rekognitionError: true,
                rekognitionErrorMessage: response.data,
              })
            } else if (error === 'PEP_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-pep`,
                state: {
                  name,
                  email,
                  document,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Blacklist_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-blacklist`,
                state: {
                  name,
                  email,
                  document,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Validation_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-validation`,
                state: {
                  errorMessage: response.data != null && response.data.length > 0 ? response.data : '',
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Called_Exists') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-exists`,
                state: {
                  name,
                  email,
                  document,
                  calledId: response.data && response.data.id ? response.data.calledid : 0,
                  fixedMessage: response.data && response.data.fixedmessage ? response.data.fixedmessage : 0,
                  message: response.data && response.data.message ? response.data.message : 0,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            }
          }

          this.setState({
            loading: false,
            /*openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"*/
          })
        }
      } else {
        this.setState({
          loading: false,
          notificationVariant: 'error',
          notificationMessage: 'Você precisa preencher os campos obrigatórios para enviar a solicitação',
          openNotification: true
        })
      }
    } else
      this.setState({ loading: false })
  }

  handleSUbmitForm = async e => {
    e.preventDefault()
    this.handleSubmit()
  }

  handleSubmit = async _ => {
    this.setState({ loading: true })

    let validateForm = await this.validateForm()
    if (validateForm === false) {
      let { item } = this.state
      
      let { name, email, document } = this.state.item
      
      let organizationFieldIds = []
      let organizationFieldValues = []
      let oFieldsValuesText = ''
      let oFieldsSuccessText = '<div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-0">'
      this.state.CalledServiceSettingsOrganizationFields.forEach(item => {
        let valueData = ''

        organizationFieldIds.push(item.organizationfieldid)
        if (this.state.item[`ofield-${item.organizationfieldid}`] != null && typeof this.state.item[`ofield-${item.organizationfieldid}`] !== 'undefined') {
          organizationFieldValues.push(this.state.item[`ofield-${item.organizationfieldid}`])
        } else {
          organizationFieldValues.push('')
        }

        if (item.organizationfield && item.organizationfield.organizationfieldtype !== 1 && item.organizationfield.organizationfieldoptionsvalues != null) {
          let valueDataOptionItem = item.organizationfield.organizationfieldoptionsvalues.find(item => item.id == this.state.item[`ofield-${item.organizationfieldid}`])
          if (valueDataOptionItem != null)
            valueData = valueDataOptionItem.name
        } else
          valueData = this.state.item[`ofield-${item.organizationfieldid}`]

        oFieldsValuesText +=`<p>${item.label}: '${valueData}'</p>`
        oFieldsSuccessText += `<div class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12" style="padding-top: 16px; color: #263238;">
          <span MuiTypography-root MuiTypography-body1" style="font-weight: bold; color: #263238; font-size: 14px; letter-spacing: -0.05px; line-height: 21px; font-family: \"Roboto\",\"Helvetica\",\"Arial\",sans-serif!important;">
            ${item.label}:&nbsp;
          </span>
          <span MuiTypography-root MuiTypography-body1" style="font-weight: 400; color: #263238; font-size: 14px; letter-spacing: -0.05px; line-height: 21px; font-family: \"Roboto\",\"Helvetica\",\"Arial\",sans-serif!important;">
            ${valueData}
          </span>
        </div>`
      })
      oFieldsSuccessText += '</div>'

      let description = `<div>
      <p>Nome do solicitante: ${item.name}</p>
      <p>CPF do solicitante: ${item.document}</p>
      <p>E-mail do solicitante: ${item.email}</p>
      ${ oFieldsValuesText }
      <br/>
      <p>Eu, ${ item.name.trim().length == 0 ? '(nome do cidadão)' : item.name }, inscrito no CPF, ${ item.document.length == 0 ? '(CPF do cidadão)' : item.document }, autorizo o Ministério da Gestão e da Inovação em Serviços Públicos a alterar meus dados cadastrais na plataforma de autenticação do GOV.BR.<br/>${ moment().format('DD/MM/YYYY') }</p>
      </div>`

      let data = new FormData()
      data.append('calledservicesettingsid', this.state.CalledServiceSettingsId)
      data.append('route', 'alterar-email-na-conta')
      data.append('username', item.name)
      data.append('useremail', item.email)
      data.append('userdocument', item.document)
      data.append('hirerid', item.hirerId)
      data.append('organizationfieldids', organizationFieldIds.join())
      data.append('organizationfieldvalues', organizationFieldValues.join('§'))
      data.append('organizationid', item.organizationId)
      data.append('description', description)
      data.append('validateuserpeplist', true)
      data.append('validateuserblacklist', true)
      

      
      
      let files = []
      Object.entries(this.state.filesUpload).forEach(([key, value]) => {
        Mexx2DOConsole.log("=== Object.entries(this.state.filesUpload) ===")
        Mexx2DOConsole.log(key)
        Mexx2DOConsole.log(typeof key)
        Mexx2DOConsole.log(value)
        Mexx2DOConsole.log(typeof value)
        if (value && value.length > 0) {
          let keyStringSplit = key.split('-')
          value.forEach(fileItem => {
            files.push({
              id: keyStringSplit[1],
              data: fileItem
            })
          })
        }
      })

      let bigFile = []
      let invalidFormatFile = []
      let fileMaxSize = 1024 * 50 // 50 MB

      files.forEach(file => {
        Mexx2DOConsole.log("=== files.forEach ===")
        Mexx2DOConsole.log(file)
        Mexx2DOConsole.log(file.data)
        Mexx2DOConsole.log(isInvalidFileType(file.data.type))

        if (isInvalidFileType(file.data.type))
          invalidFormatFile.push(file.data.name)

        let fileKbSize = Math.round((file.data.size / 1024))
        if (fileKbSize > fileMaxSize)
          bigFile.push(file.data.name)

        let randomCode = randomIntFromInterval(100, 10000)
        let renamedFile = new File([file.data], `${randomCode}_${file.data.name}`)

        data.append('filesUploadIds', file.id)
        data.append('filesUpload', renamedFile)
      });

      data.append('faceLivenessBase64Image', this.state.referenceimage)
      data.append('sessionId', this.state.sessionId  ? this.state.sessionId : null)

      Mexx2DOConsole.log("=== handleSubmit ===")
      Mexx2DOConsole.log(item)
      Mexx2DOConsole.log(this.state.files)
      Mexx2DOConsole.log(bigFile)
      Mexx2DOConsole.log(bigFile.length)
      Mexx2DOConsole.log(invalidFormatFile)
      Mexx2DOConsole.log(invalidFormatFile.length)

      if(window && window.location &&  window.location.href){
        const currentURL = window.location.href;
        data.append('absoluteOrigin', currentURL)
      }

      if (bigFile.length == 0 && invalidFormatFile.length == 0) {
        let response = await createClientExternalCalled(data)
        if (response && response.success) {
          
          this.setState({
            loading: false,
            openNotification: true,
            notificationVariant: 'success',
            notificationMessage: 'Solicitação enviada com sucesso',
            item: this.itemInitialState,
            filesUpload: {},
            subOrganizationCategoryList: [],
            files: []
          })
          if (response.data != null) {
            this.props.history.push({
              pathname: `/${this.props.match.params.lang}/attendance-success`,
              state: {
                calledId: response.data.id,
                createdAt: response.data.createdat,
                name,
                email,
                document,
                ofieldsText: oFieldsSuccessText,
                backRoute: 'alterar-email-na-conta',
                validationofexistingcalls: this.state.validationofexistingcalls
              }
            })
          }
        } else {

          if (response && response.errors && response.errors[0]) {
            let error = response.errors[0]
            if (error === 'Face_Comparisson_Error' || error === 'Document_Comparisson_Error' || error === 'Face_And_Document_Comparisson_Error') {
              this.setState({
                loading: false,
                rekognitionError: true,
                rekognitionErrorMessage: response.data,
              })
            } else if (error === 'PEP_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-pep`,
                state: {
                  name,
                  email,
                  document,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Blacklist_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-blacklist`,
                state: {
                  name,
                  email,
                  document,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Validation_Error') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-validation`,
                state: {
                  errorMessage: response.data != null && response.data.length > 0 ? response.data : '',
                  backRoute: 'alterar-email-na-conta'
                }
              })
            } else if (error === 'Called_Exists') {
              this.props.history.push({
                pathname: `/${this.props.match.params.lang}/attendance-error-exists`,
                state: {
                  name,
                  email,
                  document,
                  calledId: response.data && response.data.id ? response.data.calledid : 0,
                  fixedMessage: response.data && response.data.fixedmessage ? response.data.fixedmessage : 0,
                  message: response.data && response.data.message ? response.data.message : 0,
                  backRoute: 'alterar-email-na-conta'
                }
              })
            }
          }

          this.setState({
            loading: false,
            /*openNotification: true,
            notificationVariant: 'error',
            notificationMessage: response && response.response && response.response.data && response.response.data.errors && response.response.data.errors[0] ? response.response.data.errors[0] : "Ocorreu um erro ao processar a solicitação"*/
          })
        }
      } else {
        let errorMessage = ''

        if (bigFile.length > 0)
          errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
        if (bigFile.length > 0 && invalidFormatFile.length > 0)
          errorMessage += '\n'
        if (invalidFormatFile.length > 0) {
          errorMessage += 'São aceitos somente arquivos no formato: \n'
          errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
          errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
        }

        this.setState({
          loading: false,
          notificationVariant: 'error',
          notificationMessage: errorMessage,
          openNotification: true
        })
      }
    } else {
      this.setState({
        loading: false,
        notificationVariant: 'error',
        notificationMessage: 'Você precisa preencher os campos obrigatórios para enviar a solicitação',
        openNotification: true
      })
    }
  }

  notEventChange = (e) => {
    e.preventDefault()
  }

  _getImage = () => {
		canvas = this._capture(document.getElementById('largeVideo'), 1)
		const splitedBase64 = canvas.toDataURL('image/png').split("base64,")
	
		if (splitedBase64 && splitedBase64.length > 1) {
			var formattedBase64= splitedBase64[1]
			var showimag=document.getElementById('webcamImagePreview')
			showimag.innerHTML='<div class="image-preview-content"><button type="button" onClick="_cleanImage()" >X</button><img style="width: 100%; height: 100%;" src='+canvas.toDataURL('image/png')+' /></div>'
		let preview = document.getElementById('imagePreview')
		if (preview != null)
		  preview.innerHTML = '<img style="width: auto; height: 100%;" src='+canvas.toDataURL('image/png')+' />'
		let review = document.getElementById('imageReview')
		if (review != null)
		  review.innerHTML = '<img style="width: auto; height: 100%;" src='+canvas.toDataURL('image/png')+' />'
		}
	}
	
	_capture = (video, scaleFactor) => {
		if (scaleFactor == null)
			scaleFactor = 0.5
		let w = video.videoWidth * scaleFactor
		let h = video.videoHeight * scaleFactor
		let canvas = document.createElement('canvas')
		canvas.width = w
		canvas.height = h
	
		let ctx = canvas.getContext('2d')
		ctx.scale(1, 1)
		ctx.drawImage(video, 0, 0, w, h)

		return canvas
	}
	
	videoError = (e) => {
		Mexx2DOConsole.log('videoError')
    Mexx2DOConsole.log(e)
	}
	
	errorCallback = function(e) {
		Mexx2DOConsole.log('errorCallback')
    Mexx2DOConsole.log(e)
	}

  handleErrorFaceLivenessSession = _ => this.setState({ apiError: true })
  handleLivenessSession = (sessionId) => this.setState({ sessionId: sessionId })

  getFaceLivenessSessionResults = async (data) => {
    if (this.state.CalledServiceSettingsFileUploads.length > 0) {
      await this.setState({
        referenceimage:data.base64image,
        urlreferenceimage:data.imagetempurl,
        showliveness:false,
        step: 3,
        loading: false,
        openNotification: true,
        notificationVariant: 'success',
        notificationMessage: "Verificação de vida realizada com sucesso!"
      })
    } else
      await this.handleSubmit()
	}

  showSnackbar = (type, message) => this.setState({
      loading: false,
      openNotification: true,
      notificationVariant: type,
      notificationMessage:message
    })

  changeButtonLabel = _ => {}

  previewImage(fileData) {
		var galleryId = "gallery"
		
		var gallery = document.getElementById(galleryId)
		var imageType = /image.*/
		
	  let file = fileData
	
		if (!file.type.match(imageType)) {
			throw "File Type must be an image"
		}
		
		var thumb = document.createElement("div")
		thumb.classList.add('thumbnail') // Add the class thumbnail to the created div
		thumb.style.height = '100%'
	
		var img = document.createElement("img")
		img.file = file
	  img.style.height = '100%'
	  img.style.width = 'auto'
	
		thumb.appendChild(img)
		gallery.appendChild(thumb)
	
	  var galleryPreview = document.getElementById("galleryPreview")
	  if (galleryPreview != null) {
		  galleryPreview.innerHTML = ''
      let previewThumb = document.createElement("div")
      previewThumb.classList.add('thumbnail'); // Add the class thumbnail to the created div
      previewThumb.style.height = '100%'
    
      let previewImg = document.createElement("img")
      previewImg.file = file
      previewImg.style.height = '100%'
      previewImg.style.width = 'auto'
    
      previewThumb.appendChild(previewImg)
      galleryPreview.appendChild(previewThumb)
	
		  let reader = new FileReader()
		  reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; }; })(previewImg);
		  reader.readAsDataURL(file)
	  }
	
	  let galleryReview = document.getElementById("galleryReview")
	  if (galleryReview != null) {
      galleryReview.innerHTML = ''
      
      let reviewThumb = document.createElement("div");
      reviewThumb.classList.add('thumbnail') // Add the class thumbnail to the created div
      reviewThumb.style.height = '100%'
    
      let reviewImg = document.createElement("img")
      reviewImg.file = file
      reviewImg.style.height = '100%'
      reviewImg.style.width = 'auto'
    
      reviewThumb.appendChild(reviewImg)
      galleryReview.appendChild(reviewThumb)
    
      let reader = new FileReader()
      reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result } })(reviewImg)
      reader.readAsDataURL(file)
	  }
		
		// Using FileReader to display the image content
		let reader = new FileReader()
		reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result } })(img)
		reader.readAsDataURL(file)
	}

  updatePreviewImage = (files) => {
		if(files != null ){
			globalfiles = files
		  let file = files[0]
		  this.previewImage(file)
		}
	}

  startVideoSteps = (url,base64) => {
		let secprincipal = document.getElementById('secprincipal')
		if(secprincipal != null)
			secprincipal.style.display=''

		let tabs = document.getElementsByClassName("tab")
		this.showTab(currentTab >= tabs.length ? (tabs.length - 1) : currentTab)

		let webcamImagePreview= document.getElementById('webcamImagePreview')
		if (webcamImagePreview != null)
			webcamImagePreview.innerHTML = ''
			  
		let preview = document.getElementById('imagePreview')
		if (preview != null)
			preview.innerHTML = ''
			
		let review = document.getElementById('imageReview')
		if (review != null)
			review.innerHTML = ''

		const splitedBase64 = base64.split("base64,")
		if (splitedBase64 && splitedBase64.length > 1) {
			let showimag=document.getElementById('webcamImagePreview');
			let stringimg='<img style="width: auto; height: 100%;" src='+base64+' />';

			showimag.innerHTML='<div class="image-preview-content">'+stringimg+'</div>';

			if (preview != null)
				preview.innerHTML = stringimg;

			if (review != null)
				review.innerHTML = stringimg;
			canvas = base64;
		}

		let _self = this;			
		document.getElementById('FilesUpload').addEventListener('change', function() {
			_self.updatePreviewImage(this.files)
		}, false);
	}

  minorGuide = () => {
    window.open("https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br/duvidas-na-conta-gov.br/recuperar-conta-gov.br/titular-da-conta-e-crianca-ou-adolescente")
  }

  render() {
    const { classes, intl } = this.props;
    const { item, itemError } = this.state

    Mexx2DOConsole.log(this.state.sessionId)
        Mexx2DOConsole.log("this.state.sessionId")

    return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Header />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Grid container spacing={0} className="cardPrincipal" style={{ backgroundColor: '#F8F8F8', minHeight: 'calc(100vh - 125.6px)' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={4} lg={3} >
              <Grid container spaging={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <LogoGovBr style={{ float: 'right', marginRight: '5%' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} />
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography variant="body1" className={this.props.class} style={{ marginTop: 40, textAlign: 'right', marginRight: '5%', color: '#555', fontStyle: 'italic', fontWeight: 400 }} >
                    Atendimento ao usuário – Conta GOV.BR.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={9} style={{ width: '100%', boxSizing: 'border-box' }} >
              <Paper elevation={3} style={{ padding: '4% 5.6%', width: '100%', boxSizing: 'border-box', marginBottom: 30 }}>
                { (this.state.apiError === true && !this.state.rekognitionError) && <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                    <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4', margin: 0 }}>
                      Formulário Temporariamente Indisponível
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" className={this.props.class}>
                      Prezado(a) Cidadão(ã), para fazer o atendimento na sua conta gov.br, gentileza acessar o nosso Asssistente Virtual na página <a href="https://www.gov.br/atendimento">gov.br/chat</a>.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" className={this.props.class}>
                      Equipe gov.br
                    </Typography>
                  </Grid>
                </Grid> }
                { (!this.state.turnonfacelivenessvalidation && !this.state.apiError && !this.state.rekognitionError) && (<Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                    <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4', margin: 0 }}>
                      Como podemos ajudar?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }}>
                    <form name="myForm" onSubmit={this.handleSUbmitForm}>  
                      <Grid container spacing={2}>
                        {this.state.CalledServiceSettingsOrganizationFields.map(field => {
                          let attachmentList = field.calledservicesettingsorganizationfieldattachments && field.calledservicesettingsorganizationfieldattachments.length > 0 ? field.calledservicesettingsorganizationfieldattachments : []

                          if(field.organizationfield.organizationfieldtype == 4) {
                            if (field.organizationfield.organizationfieldoptionsvalues != null)
                              field.organizationfield.organizationfieldoptionsvalues = field.organizationfield.organizationfieldoptionsvalues.sort((a, b) => a.name - b.name)
                          }
                          
                          return (
                            <Grid key={field.id} item xs={12} sm={12} md={12} lg={12}>
                              <Grid container spacing={2}>
                                { ((field.organizationfield.organizationfieldtype === 2) || attachmentList.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                    {field.label}{field.isrequired && "*"}
                                  </Typography>
                                  { (field.organizationfield.organizationfieldtype === 2 && itemError[`ofield-${field.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${field.organizationfield.id}-text`]}</p>}
                                </Grid>}
                                { attachmentList.map(attachmentItem => {
                                  return (
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                                    </Grid>
                                  )
                                }) }
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <CustomOrganizationField
                                    organizationField={field.organizationfield}
                                    label={field.label}
                                    changeValue={this.onChangeCustomField}
                                    stateName={`ofield-${field.organizationfield.id}`} />
                                  { (field.organizationfield.organizationfieldtype !== 2 && itemError[`ofield-${field.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${field.organizationfield.id}-text`]}</p>}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        })
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 24 }}>
                            <Typography variant="p" className={this.props.class} style={{ fontWeight: 'bold' }}>
                              Titular é criança ou adolescente (menor de 18 anos)?
                            </Typography>
                            <Typography color='#3f51b5' marginLeft={1} variant='p' lassName={this.props.class} style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={this.minorGuide}>
                              Leia as orientações antes de enviar
                              </Typography>
                          </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="name"
                            label="Nome*"
                            fullWidth
                            value={item.name}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.name?.length > 0}
                            helperText={itemError.name}
                            inputProps={{ maxLength: 255 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="document"
                            label="CPF*"
                            fullWidth
                            value={item.document}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.document?.length > 0}
                            helperText={itemError.document} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="email"
                            label="E-mail*"
                            fullWidth
                            value={item.email}
                            onChange={this.handleChange}
                            onCut={this.notEventChange}
                            onCopy={this.notEventChange}
                            onPaste={this.notEventChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.email?.length > 0}
                            helperText={itemError.email} 
                            sx={{
                              '& .MuiInputBase-input': {
                                textTransform: 'lowercase'
                              }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="confirmEmail"
                            label="Confirmar E-mail*"
                            fullWidth
                            autoComplete="new-password"
                            value={item.confirmEmail}
                            onChange={this.handleChange}
                            onCut={this.notEventChange}
                            onCopy={this.notEventChange}
                            onPaste={this.notEventChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.confirmEmail?.length > 0}
                            helperText={itemError.confirmEmail} 
                            sx={{
                              '& .MuiInputBase-input': {
                                textTransform: 'lowercase'
                              }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 60 }}>
                          <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            "AUTORIZAÇÃO para alteração de dados na conta GOV.BR”
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            Para alterar seus dados cadastrais, nós precisamos do seu consentimento (Lei 13.709/2019). Sendo assim, siga as instruções abaixo:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span" className={this.props.class}>
                            Eu,&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                          { item.name.trim().length == 0 ? '(nome do cidadão)' : item.name },&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class}>
                          inscrito no CPF,&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          { item.document.length == 0 ? '(CPF do cidadão)' : item.document }, autorizo o Ministério da Gestão e da Inovação em Serviços Públicos a alterar meus dados cadastrais na plataforma de autenticação do GOV.BR.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span" className={this.props.class}>
                            { moment().format('DD/MM/YYYY') }
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          No campo "Anexos", anexe as fotos conforme os exemplos:
                        </Typography>
                      </Grid>
                      {this.state.CalledServiceSettingsFileUploads.map(item => {
                        let attachmentList = item.calledservicesettingsfileuploadattachments && item.calledservicesettingsfileuploadattachments.length > 0 ? item.calledservicesettingsfileuploadattachments : []

                        return (
                      <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                        <Grid container spacing={2}>
                          { (item.description && item.description.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div  className="fontFamilyInnerHTML" style={{ fontFamily: "'Roboto','Helvetica','Arial',sans-serif!important" }} dangerouslySetInnerHTML={ {__html: item.description } }></div>
                          </Grid>}
                          {attachmentList.map(attachmentItem => {
                          return (<Grid item xs={12} sm={6} md={6} lg={6}>
                            <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                          </Grid>)
                          })}
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                              {item.label}* {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
                            </Typography>
                            { itemError[`files-${item.id}`] && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`files-${item.id}-text`]}</p>}
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: 15 }}>
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={10} md={11} lg={11}>
                                <div className={classes.dropzoneArea}>
                                {item.filelimit > 1 ? 
                                  <Dropzone maxFiles={item.filelimit} onDrop={acceptedFiles => this.validateFiles(item.id, acceptedFiles, item.filelimit)}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        { this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                      </div>
                                    )}
                                  </Dropzone>
                                  :
                                  <Dropzone multiple={false} onDrop={acceptedFiles => {
                                    this.setState(prevState => ({
                                      filesUpload: {
                                        ...prevState.filesUpload,
                                        [`files-${item.id}`]: acceptedFiles
                                      }
                                    }))
                                  }}>
                                    {({ getRootProps, getInputProps, acceptedFiles }) => (
                                      <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        {this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                      </div>
                                    )}
                                  </Dropzone>}
                                </div>
                              </Grid>
                              {this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length > 0 &&<Grid item xs={12} sm={2} md={1} lg={1} onClick={() => { this.removeFilesUploadItems(`files-${item.id}`) }} style={{ backgroundColor: '#ef5350', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                <DeleteForever className={classes.deleteItem} />
                              </Grid>}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>)
                      })}
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 80 }}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          Atenção no momento do envio das fotos!
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" className={this.props.class}>
                        Fotos que não serão aceitas:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com óculos escuros ou de grau;
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com outras pessoas;
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com chapéu, gorro ou bonés;
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                        <Typography variant="body1" className={this.props.class}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos cortadas, desfocadas ou impróprias.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                        Não serão aceitas cópias de documentos rasurados, rasgados ou que dificultem a leitura das informações.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          Pronto! Agora, clique na opção “Enviar Solicitação”.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                      {this.state.renderFooterMessage &&
                          <Paper elevation={3} >
                            <Grid container spacing={0}>
                              <Grid item xs={12} sm={2} md={2} lg={2} style={{ backgroundColor: '#1351b4' }}></Grid>
                                <Grid item xs={12} sm={10} md={10} lg={10} style={{ padding: '25px 25px 25px 20px' }}>
                                  <Grid container spacing={0}>
                                    {( (this.state.footerMessage == null || this.state.footerMessage == "")) &&
                                      <>
                                          <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant="p" className={this.props.class} style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                            IMPORTANTE:
                                            </Typography>
                                          </Grid> 
                                          <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                            Você receberá um e-mail com o número de protocolo de atendimento. E as orientações serão enviadas para o mesmo e-mail no prazo de até 3 dias úteis.
                                            </Typography>
                                          </Grid>
                                      </>
                                    }
                                    {(this.state.footerMessage != null && this.state.footerMessage != "") &&
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                          <div dangerouslySetInnerHTML={{ __html: this.state.footerMessage }} />
                                        </Typography>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                      }
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          style={{ color: '#fff', padding: '8px 16px', textTransform: 'captalize!important' }} >
                          Enviar solicitação
                        </Button>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>)}
                { (this.state.turnonfacelivenessvalidation && this.state.step === 1 && !this.state.apiError && !this.state.rekognitionError) && (<Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: 30 }}>
                    <Typography variant="h2" className={this.props.class} style={{ color: '#1351b4', margin: 0 }}>
                      Como podemos ajudar?
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '100%', boxSizing: 'border-box' }}>
                      <Grid container spacing={2}>
                        {this.state.CalledServiceSettingsOrganizationFields.map(field => {
                          let attachmentList = field.calledservicesettingsorganizationfieldattachments && field.calledservicesettingsorganizationfieldattachments.length > 0 ? field.calledservicesettingsorganizationfieldattachments : []

                          if(field.organizationfield.organizationfieldtype == 4) {
                            if (field.organizationfield.organizationfieldoptionsvalues != null)
                              field.organizationfield.organizationfieldoptionsvalues = field.organizationfield.organizationfieldoptionsvalues.sort((a, b) => a.name - b.name)
                          }
                          
                          return (
                            <Grid key={field.id} item xs={12} sm={12} md={12} lg={12}>
                              <Grid container spacing={2}>
                                { ((field.organizationfield.organizationfieldtype === 2) || attachmentList.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                    {field.label}{field.isrequired && "*"}
                                  </Typography>
                                  { (field.organizationfield.organizationfieldtype === 2 && itemError[`ofield-${field.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${field.organizationfield.id}-text`]}</p>}
                                </Grid>}
                                { attachmentList.map(attachmentItem => {
                                  return (
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                                    </Grid>
                                  )
                                }) }
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  <CustomOrganizationField
                                    organizationField={field.organizationfield}
                                    label={field.label}
                                    changeValue={this.onChangeCustomField}
                                    stateName={`ofield-${field.organizationfield.id}`} />
                                  { (field.organizationfield.organizationfieldtype !== 2 && itemError[`ofield-${field.organizationfield.id}`]) && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`ofield-${field.organizationfield.id}-text`]}</p>}
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        })
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 24 }}>
                            <Typography variant="p" className={this.props.class} style={{ fontWeight: 'bold' }}>
                              Titular é criança ou adolescente (menor de 18 anos)?
                            </Typography>
                            <Typography color='#3f51b5' marginLeft={1} variant='p' lassName={this.props.class} style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={this.minorGuide}>
                              Leia as orientações antes de enviar
                              </Typography>
                          </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="name"
                            label="Nome*"
                            fullWidth
                            value={item.name}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.name?.length > 0}
                            helperText={itemError.name}
                            inputProps={{ maxLength: 255 }} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="document"
                            label="CPF*"
                            fullWidth
                            value={item.document}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.document?.length > 0}
                            helperText={itemError.document} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="email"
                            label="E-mail*"
                            fullWidth
                            value={item.email}
                            onChange={this.handleChange}
                            onCut={this.notEventChange}
                            onCopy={this.notEventChange}
                            onPaste={this.notEventChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.email?.length > 0}
                            helperText={itemError.email}
                            sx={{
                              '& .MuiInputBase-input': {
                                textTransform: 'lowercase'
                              }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} style={{ paddingTop: 40 }}>
                          <TextField
                            name="confirmEmail"
                            label="Confirmar E-mail*"
                            fullWidth
                            autoComplete="new-password"
                            value={item.confirmEmail}
                            onChange={this.handleChange}
                            onCut={this.notEventChange}
                            onCopy={this.notEventChange}
                            onPaste={this.notEventChange}
                            InputLabelProps={{ shrink: true }}
                            error={itemError.confirmEmail?.length > 0}
                            helperText={itemError.confirmEmail}
                            sx={{
                              '& .MuiInputBase-input': {
                                textTransform: 'lowercase'
                              }
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 60 }}>
                          <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            "AUTORIZAÇÃO para alteração de dados na conta GOV.BR”
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            Para alterar seus dados cadastrais, nós precisamos do seu consentimento (Lei 13.709/2019). Sendo assim, siga as instruções abaixo:
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span" className={this.props.class}>
                            Eu,&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                          { item.name.trim().length == 0 ? '(nome do cidadão)' : item.name },&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class}>
                          inscrito no CPF,&nbsp;
                          </Typography>
                          <Typography variant="body1" component="span" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          { item.document.length == 0 ? '(CPF do cidadão)' : item.document }, autorizo o Ministério da Gestão e da Inovação em Serviços Públicos a alterar meus dados cadastrais na plataforma de autenticação do GOV.BR.
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Typography variant="body1" component="span" className={this.props.class}>
                            { moment().format('DD/MM/YYYY') }
                          </Typography>
                        </Grid>
                        {this.state.CalledServiceSettingsFileUploadsNotRecognition.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                          <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                            No campo "Anexos", anexe as fotos conforme os exemplos:
                          </Typography>
                        </Grid>}
                      {this.state.CalledServiceSettingsFileUploadsNotRecognition.map(item => {
                        let attachmentList = item.calledservicesettingsfileuploadattachments && item.calledservicesettingsfileuploadattachments.length > 0 ? item.calledservicesettingsfileuploadattachments : []

                        return (
                        <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, paddingBottom: 40 }}>
                          <Grid container spacing={2}>
                            { (item.description && item.description.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
                              <div  className="fontFamilyInnerHTML" style={{ fontFamily: "'Roboto','Helvetica','Arial',sans-serif!important" }} dangerouslySetInnerHTML={ {__html: item.description } }></div>
                            </Grid>}
                            {attachmentList.map(attachmentItem => {
                            return (<Grid item xs={12} sm={6} md={6} lg={6}>
                              <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                            </Grid>)
                            })}
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                              <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                                {item.label}* {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
                              </Typography>
                              { itemError[`files-${item.id}`] && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`files-${item.id}-text`]}</p>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: 15 }}>
                              <Grid container spacing={0}>
                                <Grid item xs={12} sm={10} md={11} lg={11}>
                                  <div className={classes.dropzoneArea}>
                                  {item.filelimit > 1 ? 
                                    <Dropzone maxFiles={item.filelimit} onDrop={acceptedFiles => this.validateFiles(item.id, acceptedFiles, item.filelimit)}>
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          { this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                        </div>
                                      )}
                                    </Dropzone>
                                    :
                                    <Dropzone multiple={false} onDrop={acceptedFiles => {
                                      this.setState(prevState => ({
                                        filesUpload: {
                                          ...prevState.filesUpload,
                                          [`files-${item.id}`]: acceptedFiles
                                        }
                                      }))
                                    }}>
                                      {({ getRootProps, getInputProps, acceptedFiles }) => (
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          {this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                        </div>
                                      )}
                                    </Dropzone>}
                                  </div>
                                </Grid>
                                {this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length > 0 &&<Grid item xs={12} sm={2} md={1} lg={1} onClick={() => { this.removeFilesUploadItems(`files-${item.id}`) }} style={{ backgroundColor: '#ef5350', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                                  <DeleteForever className={classes.deleteItem} />
                                </Grid>}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>)
                      })}
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingTop: '30px' }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} md={3} lg={2} />
                          <Grid item xs={12} sm={12} md={6} lg={8} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={this.handleSubmitStep}
                              style={{ color: '#fff', padding: '8px 16px', textTransform: 'captalize!important' }} >
                              Prosseguir para verificação de vida
                            </Button>
                          </Grid>
                          <Grid item xs={12} sm={12} md={3} lg={2} />
                        </Grid>
                      </Grid>
                  </Grid>
                    </Grid>)}
                { (this.state.turnonfacelivenessvalidation && this.state.step === 2 && !this.state.apiError && !this.state.rekognitionError) && (<Grid container spacing={0}>
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingBottom: '15px' }} >
                    <Button
                      onClick={() => {
                        this.setState({ step: 1 })
                      }}
                      variant="outlined"
                      color="primary"
                      style={{ padding: '8px 16px', textTransform: 'captalize!important' }} >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <article className="liveness-face" >
                        <LivenessFaceClass
                          getFaceLivenessSessionResults={this.getFaceLivenessSessionResults}
                          handleErrorFaceLivenessSession={this.handleErrorFaceLivenessSession}
                          showSnackbar={this.showSnackbar}
                          changeButtonLabel={this.changeButtonLabel} 
                          hirerId = {this.state.item && this.state.item.hirerId != null ?  this.state.item.hirerId: null}
                          handleLivenessSession = {this.handleLivenessSession}
                          document = {this.state.item && this.state.item.document != null ?  this.state.item.document: null}
                          name = {this.state.item && this.state.item.name != null ?  this.state.item.name: null}
                          email = {this.state.item && this.state.item.email != null ?  this.state.item.email: null}
                          
                          />
                    </article>
                  </Grid>
                    </Grid>)}
                { (this.state.turnonfacelivenessvalidation && this.state.step === 3 && !this.state.apiError && !this.state.rekognitionError) && (<Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12} sx={{ paddingBottom: '15px' }} >
                    <Button
                      onClick={() => {
                        this.setState({ step: 2 })
                      }}
                      variant="outlined"
                      color="primary"
                      style={{ padding: '8px 16px', textTransform: 'captalize!important' }} >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                    <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      No campo "Anexos", anexe as fotos conforme os exemplos:
                    </Typography>
                  </Grid>
                  {this.state.CalledServiceSettingsFileUploads.map(item => {
                    let attachmentList = item.calledservicesettingsfileuploadattachments && item.calledservicesettingsfileuploadattachments.length > 0 ? item.calledservicesettingsfileuploadattachments : []

                    return (
                  <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                    <Grid container spacing={2}>
                      { (item.description && item.description.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
                        <div  className="fontFamilyInnerHTML" style={{ fontFamily: "'Roboto','Helvetica','Arial',sans-serif!important" }} dangerouslySetInnerHTML={ {__html: item.description } }></div>
                      </Grid>}
                      {attachmentList.map(attachmentItem => {
                      return (<Grid item xs={12} sm={6} md={6} lg={6}>
                        <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
                      </Grid>)
                      })}
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                          {item.label}* {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
                        </Typography>
                        { itemError[`files-${item.id}`] && <p style={{ color: '#E53935', fontSize: '11px', letterSpacing: '0.33px', lineHeight: '13px', fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontWeight: 400, textAlign: 'left', marginTop: '3px', marginRight: '14px', marginBottom: 0, marginLeft: '14px' }}>{itemError[`files-${item.id}-text`]}</p>}
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: 15 }}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={10} md={11} lg={11}>
                            <div className={classes.dropzoneArea}>
                            {item.filelimit > 1 ? 
                              <Dropzone maxFiles={item.filelimit} onDrop={acceptedFiles => this.validateFiles(item.id, acceptedFiles, item.filelimit)}>
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    { this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                  </div>
                                )}
                              </Dropzone>
                              :
                              <Dropzone multiple={false} onDrop={acceptedFiles => {
                                this.setState(prevState => ({
                                  filesUpload: {
                                    ...prevState.filesUpload,
                                    [`files-${item.id}`]: acceptedFiles
                                  }
                                }))
                              }}>
                                {({ getRootProps, getInputProps, acceptedFiles }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {this.state.filesUpload[`files-${item.id}`] == null || (this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length == 0) ? <Typography variant="body1" className={this.props.class}>Arraste seus arquivos ou clique aqui para selecionar</Typography> : <Typography variant="body1" className={this.props.class}>{this.state.filesUpload[`files-${item.id}`].length} arquivos selecionados</Typography>}
                                  </div>
                                )}
                              </Dropzone>}
                            </div>
                          </Grid>
                          {this.state.filesUpload[`files-${item.id}`] && this.state.filesUpload[`files-${item.id}`].length > 0 &&<Grid item xs={12} sm={2} md={1} lg={1} onClick={() => { this.removeFilesUploadItems(`files-${item.id}`) }} style={{ backgroundColor: '#ef5350', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}>
                            <DeleteForever className={classes.deleteItem} />
                          </Grid>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>)
                  })}
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 80 }}>
                    <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      Atenção no momento do envio das fotos!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                    Fotos que não serão aceitas:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com óculos escuros ou de grau;
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com outras pessoas;
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos com chapéu, gorro ou bonés;
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 0 }}>
                    <Typography variant="body1" className={this.props.class}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;&nbsp;Fotos cortadas, desfocadas ou impróprias.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                    <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                    Não serão aceitas cópias de documentos rasurados, rasgados ou que dificultem a leitura das informações.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="body1" className={this.props.class} style={{ fontWeight: 'bold' }}>
                      Pronto! Agora, clique na opção “Enviar Solicitação”.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40 }}>
                    {this.state.renderFooterMessage &&
                      <Paper elevation={3} >
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={2} md={2} lg={2} style={{ backgroundColor: '#1351b4' }}></Grid>
                            <Grid item xs={12} sm={10} md={10} lg={10} style={{ padding: '25px 25px 25px 20px' }}>
                              <Grid container spacing={0}>
                                {( (this.state.footerMessage == null || this.state.footerMessage == "")) &&
                                  <>
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant="p" className={this.props.class} style={{ fontWeight: 'bold', marginBottom: 0 }}>
                                        IMPORTANTE:
                                        </Typography>
                                      </Grid> 
                                      <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                        Você receberá um e-mail com o número de protocolo de atendimento. E as orientações serão enviadas para o mesmo e-mail no prazo de até 3 dias úteis.
                                        </Typography>
                                      </Grid>
                                  </>
                                }
                                {(this.state.footerMessage != null && this.state.footerMessage != "") &&
                                  <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Typography variant="p" className={this.props.class} style={{ marginBottom: 0 }}>
                                      <div dangerouslySetInnerHTML={{ __html: this.state.footerMessage }} />
                                    </Typography>
                                  </Grid>
                                }
                              </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    }
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 40, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={this.handleSUbmitForm}
                      type="submit"
                      variant="contained"
                      color="primary"
                      style={{ color: '#fff', padding: '8px 16px', textTransform: 'captalize!important' }} >
                      Enviar solicitação
                    </Button>
                  </Grid>
                </Grid>)}
                {(this.state.rekognitionError === true && !this.state.apiError) && (<Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="body1" className={this.props.class}>
                      <div dangerouslySetInnerHTML={{__html: this.state.rekognitionErrorMessage}} />
                    </Typography>
									</Grid>
									<Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 10, display: 'flex', justifyContent: 'center' }}>
                    <Button
                      onClick={() => this.setState({ rekognitionError: false, step: 3, rekognitionErrorMessage: '' })}
                      variant="contained"
                      color="mainprimary"
                      style={{ padding: '8px 16px', textTransform: 'captalize!important' }} >
                      Tente novamente
                    </Button>
                  </Grid>
                </Grid>)}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <Footer />
        {this.state.loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}
        <CustomizedSnackbars
          variant={this.state.notificationVariant}
          message={this.state.notificationMessage}
          isOpen={this.state.openNotification}
          toClose={this.closeNotification} />
      </Grid>
    </Grid>
    )
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  headerMenu: state.headerMenu,
  userSession: state.userSession,
});

export default injectIntl(connect(mapStateToProps)(withStyles(Styles)(Home)));